export const setting = {
    bgcolor : "#49a7a7",
    title : window.location.hostname.replaceAll("www.","").split(".")[0].toUpperCase(),
    lp :"https://ltdquiz.com/check/",
    google_gtm : "GTM-FWH388P",
    google_aw :"AW-880835676",
    utm_accountId:"141-541-3783",
    utm_account:"Airtrade",
    domain : window.origin+"/",
    conversion: "AW-880835676/07RFCNno0dsDENz4gaQD",
    shortLink :"https://whtapp.co/p-pGnKXOkw",
    email : "info@"+window.location.hostname.replaceAll("www.","")
}
