import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Policy from "./layout/policy";
import Home from "./layout/home";
import "bootstrap/dist/css/bootstrap.rtl.min.css"
import Thanks from "./layout/thanks";
function App() {
  return (
    <div className="App h-100">
      <Routes>
          <Route path={"*"} index element={<Home />} />
          <Route index path={"/cookie-policy"} element={<Policy />} />
          <Route index path={"/privacy"} element={<Policy />} />
          <Route index path={"/thank-you"} element={<Thanks />} />

      </Routes>
    </div>
  );
}

export default App;
