import React, {useEffect} from "react"
import { FaRegCheckCircle} from "react-icons/fa";
import {Col, Container, Row} from "react-bootstrap";
import {setting} from "../helper/setting";


function Thanks() {
    return(
        <Container className={"d-flex h-100 flex-column "} >
            <Row className={"h-100 justify-content-center align-items-center text-center"}>
                <Col>
                    <div><FaRegCheckCircle size={80} color={"#40785b"} /></div>
                    <h1 className={"fw-bold py-3 text-center"}>شكرا لانضمامك إلينا</h1>
                    <a className={"btn btn-primary fs-5 fw-bold"} href={setting.shortLink} target={"_blank"}>احصل على استشارة مجانية
                    </a>
                </Col>
            </Row>

        </Container>
    )
}
export default Thanks
