import banner from '../images/banner_img.png'
import img1 from '../images/h2_about_img.png'
import img2 from '../images/download_img.png'

import icon1 from '../images/icon1.svg'
import icon2 from '../images/icon2.svg'
import icon3 from '../images/icon3.svg'
import icon4 from '../images/icon4.svg'
import logo from '../images/logo.png'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {CgClose} from "react-icons/cg";

import {Col, Container, Image, Modal, Nav,Form,Button,Spinner, Navbar, Offcanvas, Row} from "react-bootstrap";
import {useState} from "react";
import {setting} from "../helper/setting";
function Home (){
    const nav = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [menuOpen, setMenuOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState({msg:"",status:""});

    const toggleMenu = () => {
        if(window.innerWidth > 1200){
            setMenuOpen(false)
        }else{
            setMenuOpen(!menuOpen)
        }
    }
    const handleClose = () => setMenuOpen(false)

    const handleSubmit = async (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true)
            setMsg({msg:"",status:""});
            const formData = new FormData(event.target);
            axios.post(setting.domain+'/Data',formData,
                {
                    method: "post",
                },
            ).then(async function (response) {


                if(!document.getElementById("send_form")){
                    if(parseInt(event.target.Citizen.value) == 1) {
                        const script = document.createElement('script');

                        script.id = "send_form";


                        //let lead = "enhanced_conversion_data.address.first_name='"+event.target.name.value+"';enhanced_conversion_data.phone_number='"+event.target.phone.value+"';enhanced_conversion_data.email='"+event.target.email.value+"';"
                        //lead =  lead +"gtag('set','user_data',{'email':'"+event.target.email.value+"','phone_number': '"+event.target.phone.value+"','address': [{'first_name': '"+event.target.name.value+"','last_name': '-', 'street':'-' , 'city': '-', 'region':'-', 'country':'-', 'postal_code':'-'}],'_tag_mode': 'MANUAL'});gtag('config', 'AW-452924989');"
                        script.innerHTML = "dataLayer.push ({ 'event':'send_form' });" + "gtag('event', 'conversion', {'send_to': '" + setting.conversion + "'});";
                        document.body.appendChild(script);
                    }
                }


                setMsg({msg:"شكرا لك، تم الحفظ",status:"success"})
                setValidated(false)
                setLoading(false)
                setTimeout(()=>{
                    nav("/thank-you")
                },300)

            }).catch(function (error) {
                setMsg({msg:"حاول مرة آخرى",status:"danger"})
                setLoading(false)
            });
        }
    };


    const handleCloseForm = () => {
        setShow(false);
        setValidated(false);
        setMsg({msg:"",status:""})
    }


    return(
        <>
            <header>
                <Container>
                    <Row>
                        <Col>
                            <Navbar expand={"xl"} bg="none"  >
                                <Navbar.Brand  className={"order-1 order-xl-0"} href={"#Main"}>

                                    <Image srcSet={logo} width={150} alt={""} />
                                </Navbar.Brand>
                                <Navbar.Toggle  onClick={toggleMenu} className={"order-0 justify-content-center order-xl-0"} aria-controls={`offcanvasNavbar-expand-false`} />
                                <Navbar.Offcanvas            restoreFocus={false}
                                                             show={menuOpen}
                                                             onHide={handleClose}

                                                             id={`offcanvasNavbar-expand-false`} aria-labelledby={`offcanvasNavbarLabel-expand-false`} placement="start">
                                    <Offcanvas.Header closeButton >
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                                <div  className={"text-dark h2"}>{setting.title}</div>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-center text-center flex-grow-1 pe-md-3 pe-0">
                                            <Nav.Link   onClick={toggleMenu} href={"#Main"}>الرئيسية</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#dalel"}>دليل الاستثمار</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#training"}>دوراتنا</Nav.Link>
                                            <Nav.Link   onClick={toggleMenu} href={"#advice"}>نصائح مهمة</Nav.Link>
                                            <button style={{backgroundColor:setting.bgcolor}} className={"btn mt-2 d-md-block d-lg-none border-0 mx-1 btn-outline-primary  btn-sm fs-6  py-1 px-4   fs-6 text-white  rounded-pill "} onClick={()=>{setShow(true)}}>  سجل الان </button>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                <div className={"d-md-inline-flex order-2 d-none"}>
                                    <button style={{backgroundColor:setting.bgcolor}} className={"btn border-0 mx-1 btn-outline-primary  btn-sm fs-6  py-1 px-4   fs-6 text-white  rounded-pill "} onClick={()=>{setShow(true)}}>  سجل الان </button>
                                </div>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
            <main id={"Main"}>
                <section className="banner-area-two text-white">
                    <div className="banner-bg-two"></div>
                    <div className="container custom-container-four">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="banner-content text-center">
                                    <h2 className="title">تعلم الان مهارات الاستثمار وإدارة المال</h2>
                                    <Image srcSet={banner} alt="img" />
                                    </div>
                            </div>
                        </div>
                    </div>
                 </section>
                <section>
                    <Container>
                        <Row>
                            <Col >
                                <div className={"box px-3 py-5 pb-4 "}>
                                    <div className={"text-center h5 fw-bold"}>تعلم الان مهارات الاستثمار وإدارة المال، من الصفر حتى الاحترافية مع دوراتنا المختلفة في عالم الاستثمار .</div>
                                    <div className={"text-center fw-bold"}>منصة الخليج السعودية تتيح الان تعلم الاستثمار بشكل دائم في أي وقت ومن أي مكان من خلال توفير العديد من:</div>
                                    <Row className={"my-4"}>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>الدورات التدريبية للمبتدئين</div>
                                        </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>دورات تدريبية احترافية</div>

                                        </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>مواد تعليمية لمختلف المتداولين</div>
                                            </Col>
                                        <Col md={3} className={"mb-2"}>
                                            <div className={"rounded-pill h-100 text-center p-2 text-white"} style={{backgroundColor:setting.bgcolor}}>دليل الاحتراف من الصفر حتى الاحتراف</div>

                                        </Col>
                                    </Row>

                                    <div className={"text-center"}>وغيرها الكثير تعرف معنا الان وابدأ رحلة التعلم الخاصة بك</div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <section className={"py-5 my-md-5"} id={"dalel"}>
                <Container>
                    <Row className={"align-items-center"}>
                        <Col md={7}>
                            <div className={"h5"}>قبل أن تستثمر أموالك، استثمر  معنا في تعلم الأساسيات وفهم المخاطر.</div>
                            <div >  لذلك، ابتعدنا عن التفاصيل غير الضرورية لنقدم لك دليلاً يمكن أن يساعدك في الحياة في هذا العالم الجديد والمثير.</div>
                                <div className={"fw-bold my-3"}>ماذا يشمل دليل الاستثمار للمبتدئين الخاص بمنصة الخليج:</div>
                            <ul>
                                <li>أهمية الاستثمار</li>
                                <li>كيفية البدء في الاستثمار وإدارة الأموال</li>
                                <li>الوقت المناسب للاستثمار؟</li>
                                <li>أدوات الاستثمار المتنوعة</li>
                                <li>فهم الأسواق المالية ونسبة المخاطرة</li>
                                <li>الاختلافات بين الأسهم والسندات وكيفية الاستثمار في هذه الأدوات الاستثمارية</li>
                                <li>كيفية بناء سياسة استثمارية وكيفية ادارة الاستثمارات</li>
                                <li>بعض الأساليب التي تساعد في تقييم الاستثمارات</li>
                            </ul>

                        </Col>
                        <Col md={5}>
                            <Image srcSet={img2} className={"w-100"} alt={""} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={"training"} className={"choose-bg py-5"}>
                <Container className={"py-5"}>
                    <Row className={"text-white "}>
                        <Col md={12}>
                            <div className={"text-center "}>
                                <h3 className={"h2 fw-bold"} >جاهز لتبدأ رحلة التعلم؟</h3>
                                <p className={"h5 mt-3"}>سجّل الآن باحدى الدورات التالية وابدأ رحلة العِلم إلى الاستثمار</p>
                            </div>
                            <Row className={"align-items-center mt-5 "}>
                                <Col md={3} xs={6} className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon1} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>مبادئ الاستثمار</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon2} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>كيف أبدأ بالاستثمار المالي</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon3} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>طرق تقييم الاستثمارات</div>
                                    </div>
                                </Col>
                                <Col md={3} xs={6}  className={"mb-3 mb-md-0"}>
                                    <div className={"text-center h-100 d-flex flex-column justify-content-center"}>
                                        <div><Image srcSet={icon4} width={70} alt={""} /> </div>
                                        <div className={"mt-2"}>الأصول الاستثمارية المالية</div>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={"advice"} className={"py-5 my-md-3"}>
                <Container>
                    <Row className={"align-items-center"}>
                        <Col md={7}>
                            <div className={"h5 fw-bold my-md-3"}>هناك 5 نصائح مهمة نقدمها لك يجب تذكرها قبل أن تبدأ بالاستثمار</div>

                            <ul>
                                <li>ابدا بتعلم الاستثمار وكيفية تجنب المخاطر المتعلقة بالخسارة</li>
                                <li>حدد المبلغ الذي يمكنك توفيره واستثماره.</li>
                                <li>فكر في البدء على نطاق صغير  أولا لترى كيف ستسير الأمور.</li>
                                <li>كن مستعدًا لعدم الاعتماد على صرف استثمارك لمدة 5 سنوات على الأقل.</li>
                                <li>ضع في اعتبارك طلب المساعدة والاستشارة لتحديد الخيار المناسب لك.</li>
                            </ul>

                        </Col>
                        <Col md={5}>
                            <Image srcSet={img1} className={"w-100"} alt={""} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className={"bg-black py-5"}>
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Nav className="justify-content-center text-center ">
                                <Nav.Link  className={"text-white d-none d-md-block"}  href={"#Main"}>الرئيسية</Nav.Link>
                                <Nav.Link  className={"text-white"} href={"#dalel"}>دليل الاستثمار</Nav.Link>
                                <Nav.Link  className={"text-white"}  href={"#training"}>دوراتنا</Nav.Link>
                                <Nav.Link  className={"text-white"} href={"#advice"}>نصائح مهمة</Nav.Link>
                            </Nav>
                        </Col>
                        <Col md={12} className={"text-white small text-center py-3"}>
                            يمكن مراسلة إدارة موقع تداول.كوم بشكل مباشر للتحكم بالبيانات الخاصة بك وطلب تعديلها أو الغائها بشكل مباشر من خلال التواصل مع موقع {setting.email}.
                        </Col>
                        <Col md={12} className="text-justify  text-white">
                            <div className="fw-bold my-2 text-center">
                                <Link to="./cookie-policy" style={{color:setting.bgcolor}} className="text-decoration-none fw-light">شروط الاستخدام</Link><span> و </span>
                                <Link to="./cookie-policy" style={{color:setting.bgcolor}} className="text-decoration-none fw-light" >سياسة الخصوصية</Link>
                            </div>
                            <div className="text-center">حقوق النشر © {new Date().getFullYear()} جميع الحقوق محفوظة</div>
                        </Col>
                    </div>
                </div>
            </footer>

            <Modal   centered={true} size={"lg"} show={show} onExited={handleCloseForm} onHide={handleCloseForm} backdrop="static">

                <Form noValidate validated={validated} id={"submitForm"} onSubmit={handleSubmit}>
                    <Modal.Body>

                        <Row className={"py-4"}>

                            <input type="hidden" name="action" value="senddata" />
                            <input type="hidden" name="LeadSource" value="snad" />
                            <input type="hidden" name="utm[source]" value={searchParams.get("utm_source") != null ? (searchParams.get("utm_source")) as string  : ""} />
                            <input type="hidden" name="utm[medium]" value={searchParams.get("utm_medium") != null ? (searchParams.get("utm_medium")) as string : ""} />
                            <input type="hidden" name="utm[campaign]" value={searchParams.get("utm_campaign") != null ? (searchParams.get("utm_campaign")) as string : ""} />
                            <input type="hidden" name="utm[term]" value={searchParams.get("utm_term") != null ? (searchParams.get("utm_term")) as string : ""} />
                            <input type="hidden" name="utm[content]" value={searchParams.get("utm_content") != null ? (searchParams.get("utm_content")) as string : ""} />
                            <input type="hidden" name="utm[creative]" value={searchParams.get("utm_creative") != null ? (searchParams.get("utm_creative")) as string : ""} />
                            <input type="hidden" name="utm[adgroupid]" value={searchParams.get("utm_adgroupid") != null ? (searchParams.get("utm_adgroupid")) as string : ""} />
                            <input type="hidden" name="utm[adgroupname]" value={searchParams.get("utm_adgroupname") != null ? (searchParams.get("utm_adgroupname")) as string : ""} />
                            <input type="hidden" name="utm[adposition]" value={searchParams.get("utm_adposition") != null ? (searchParams.get("utm_adposition")) as string : ""} />
                            <input type="hidden" name="utm[campaignid]" value={searchParams.get("utm_campaignid") != null ? (searchParams.get("utm_campaignid")) as string : ""} />
                            <input type="hidden" name="utm[campaignname]" value={searchParams.get("utm_campaignname") != null ? (searchParams.get("utm_campaignname")) as string : ""} />
                            <input type="hidden" name="utm[country]" value={searchParams.get("utm_country") != null ? (searchParams.get("utm_country")) as string : ""} />
                            <input type="hidden" name="utm[device]" value={searchParams.get("utm_device") != null ? (searchParams.get("utm_device")) as string : ""} />
                            <input type="hidden" name="utm[feeditemid]" value={searchParams.get("utm_feeditemid") != null ? (searchParams.get("utm_feeditemid")) as string : ""}  />
                            <input type="hidden" name="utm[keyword]" value={searchParams.get("utm_keyword") != null ? (searchParams.get("utm_keyword")) as string : ""} />
                            <input type="hidden" name="utm[landingpage]" value={searchParams.get("utm_landingpage") != null ? (searchParams.get("utm_landingpage")) as string : ""} />
                            <input type="hidden" name="utm[language]" value={searchParams.get("utm_language") != null ? (searchParams.get("utm_language")) as string : ""} />
                            <input type="hidden" name="utm[loc_interest_ms]" value={searchParams.get("utm_loc_interest_ms") != null ? (searchParams.get("utm_loc_interest_ms")) as string : ""} />
                            <input type="hidden" name="utm[loc_physical_ms]" value={searchParams.get("utm_loc_physical_ms") != null ? (searchParams.get("utm_loc_physical_ms")) as string : ""} />
                            <input type="hidden" name="utm[matchtype]" value={searchParams.get("utm_matchtype") != null ? (searchParams.get("utm_matchtype")) as string : ""}  />
                            <input type="hidden" name="utm[placement]" value={searchParams.get("utm_placement") != null ? (searchParams.get("utm_placement")) as string : ""}  />
                            <input type="hidden" name="utm[targetid]" value={searchParams.get("utm_targetid") != null ? (searchParams.get("utm_targetid")) as string : ""}  />
                            <input type="hidden" name="utm[gclid]" value={searchParams.get("gclid") != null ? (searchParams.get("gclid")) as string : ""}  />
                            <input type="hidden" name="utm[utm_accountId]" value={searchParams.get("utm_account_id") != null ? (searchParams.get("utm_account_id")) as string : ""}  />
                            <input type="hidden" name="utm[utm_account]" value={searchParams.get("utm_account") != null ? (searchParams.get("utm_account")) as string : ""}   />

                            {
                                msg.msg &&
                                <Col md={12}>
                                    <div className={"alert alert-light-"+msg.status+" text-center border-0"} role="alert">
                                        {msg.msg}
                                    </div>
                                </Col>
                            }
                            <Col md={12} className={"px-md-5 px-3"}>
                                <a href={"#"} type="button" onClick={()=>{setShow(false)}} className="close p-0 m-0"  aria-label="Close">
                                    <CgClose />
                                </a>
                                <Row>
                                    <Col md={12} className={"mb-3"}>
                                        <h1 className={"text-center fw-bold"}>
                                            سجل الآن وابدأ خطواتك
                                            <br />
                                            الاولى لجني الارباح
                                        </h1>
                                    </Col>
                                    <Col md={12} className={"my-1"}>
                                        <Form.Group className={"position-relative"}>
                                            <Form.Control type={"text"} name={"name"} id={"name"}  aria-describedby="msg_name" required placeholder={"الاسم الكامل"} aria-label={"الاسم الكامل"} />
                                            <Form.Control.Feedback type="invalid"  id="msg_name" >
                                                حقل اجباري
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className={"my-1"}>
                                        <Form.Group className={"position-relative"}>
                                            <Form.Control type={"email"} name={"email"} id={"email"}  aria-describedby="msg_email" required placeholder={"البريد الالكتروني"} aria-label={"البريد الالكتروني"} />
                                            <Form.Control.Feedback type="invalid"  id="msg_email" >
                                                ادخل بريد الالكتروني صحيح
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className={"my-1"}>
                                        <Form.Group className={"position-relative"}>
                                            <Form.Control type={"tel"} name={"phone"} id={"phone"} pattern={"^([0-9]{9,10})$"}  aria-describedby="msg_phone" required  placeholder={"رقم الجوال"} aria-label={"رقم الجوال"} />
                                            <small className={"text-black-50"}>مثال : 0554321678</small>
                                            <Form.Control.Feedback type="invalid"  id="msg_phone" >
                                                ادخل رقم هاتف صحيح
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className={"my-1 mt-2"}>
                                        <Form.Group className={"mb-1 mb-3 text-start form"} controlId={"Citizen"}>
                                            <Form.Label className={"pe-2"}>حدد جنسيتك؟</Form.Label>
                                            <Form.Check type={"radio"} required inline label={"سعودي"} defaultValue={1}  id={"Citizen2"}    name={"Citizen"}  />
                                            <Form.Check type={"radio"} required inline  label={"اجنبي"} defaultValue={0}  id={"Citizen1"}     name={"Citizen"}  />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className={"text-center pt-2"}>
                                        <Button type={"submit"}  id={"submitBtn"} disabled={loading} className={"fw-bold bg-color1"} >
                                            {
                                                loading ?
                                                    <><Spinner variant={"light"} placeholder={"انتظر من فضلك"} /></>
                                                    :
                                                    <>أرسل الآن</>
                                            }

                                        </Button>
                                        <p />
                                        <small >بالضغط على زر التسجيل انت توافق على <Link className={"text-decoration-none fw-bold color1"} to={"privacy"}>شروط الاستخدام</Link> و <Link className={"text-decoration-none fw-bold color1"} to={"privacy"}>سياسة الخصوصية</Link></small>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>


            {
                window.location.origin.includes("mrtrsa.com") &&
                <Modal show={true} centered backdrop={true} >
                    <Modal.Body dir={"ltr"} className={"text-center"}>
                        <h1>Important Notice!</h1>
                        <p>We've changed our domain to a new and modern one.
                            <br />
                            Please update your bookmarks!</p>
                    </Modal.Body>
                </Modal>
            }

        </>
    )
}
export default Home
